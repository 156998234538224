import ArticleCard, { ArticleCardProps } from './ArticleCard';
import { AuthorProps, CoverImageProps } from '../../utils/blog/types';

import React from 'react';
import { countWords } from '../../utils/blog';
import { device } from '../../utils/blog/breakpoints';
import styled from 'styled-components';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 24px;
  grid-template-areas: '.';
  grid-template-columns: 1fr;
  > * {
    display: flex;
  }
  @media ${device.md} {
    gap: 64px 32px;
    grid-template-areas: '. . .';
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const Title = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 1;
  margin-bottom: 40px;
`;

type ArticleType = {
  uid: string;
  last_publication_date?: Date | null;
  tags?: string[] | null;
  data: {
    author: AuthorProps | null;
    body: any[] | null;
    cover_image: CoverImageProps | null;
    excerpt: string | null;
    hero_title: string | null;
  };
};

type RelatedArticlesProps = { articles: ArticleType[]; title?: string };

const RelatedArticles = ({ articles, title }: RelatedArticlesProps) => {
  return (
    <>
      <div className="m-section__wrap">
        {title && <Title>{title}</Title>}
        <Grid>
          {articles.map(
            (
              {
                tags,
                uid,
                last_publication_date,
                data: { cover_image, hero_title, excerpt, body, author },
              }: ArticleType,
              index: number
            ) => {
              const props: ArticleCardProps = {
                cover_image,
                hero_title,
                word_count: countWords(
                  body.filter((val) => val.slice_type === 'paragraph')
                ),
                excerpt,
                tags,
                link: `/blog/${uid}`,
                author,
                last_published: last_publication_date,
              };
              return <ArticleCard {...props} key={`${uid}_${index}`} />;
            }
          )}
        </Grid>
      </div>
    </>
  );
};

export default RelatedArticles;

import CTA, { CTAProps } from '../../components/blog/CTA';
import React, { useEffect, useState } from 'react';
import { calcReadingTime, countWords } from '../../utils/blog';
import { useIntl } from 'gatsby-plugin-intl';

import { AuthorPFP } from '../../components/blog/ArticleCard';
import { BlogPageProps } from '../../utils/blog/types';
import Image from '../../components/blog/slices/Image';
import Layout from '../../components/layout';
import NotFoundPage from '../404';
import Quote from '../../components/blog/slices/Quote';
import RelatedArticles from '../../components/blog/RelatedArticles';
import SEO from '../../components/seo';
import Text from '../../components/blog/slices/Text';
import { device } from '../../utils/blog/breakpoints';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

const Hero = styled.section`
  margin-top: 56px;
  img {
    max-width: 100vw;
    width: 100%;
    object-fit: cover;
    object-position: center;
    height: 250px;
    @media ${device.lg} {
      height: 420px;
    }
  }
`;

const Teaser = styled.div`
  padding: 40px 0px;
  @media ${device.lg} {
    padding: 40px 64px;
  }
  font-family: 'Inter';
  font-style: normal;
  .title {
    font-weight: 500;
    font-size: 28px;
    line-height: 133%;
    @media ${device.md} {
      font-size: 32px;
      line-height: 150%;
      text-align: center;
    }
  }
`;
const Author = styled.div`
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  display: flex;
  @media ${device.lg} {
    margin-top: 24px;
  }
  .author-pfp {
    display: flex;
    justify-content: center;
    @media ${device.lg} {
      display: inline-block;
    }
  }
  .author-details {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 8px;
    @media ${device.lg} {
      display: inline-flex;
      margin-top: 0;
    }
  }
  .author-name {
    font-size: 16px;
    line-height: 150%;
    font-weight: 500;
    margin-right: 24px;
    display: flex;
    width: 100%;
    @media ${device.lg} {
      display: inline-flex;
      width: auto;
    }
  }
  .author-reading_time,
  .author-date {
    font-weight: 400;
    font-size: 14px;
    line-height: 1;
  }
`;

const RelatedArticlesStyled = styled.section`
  background: #f6f9fe;
  padding: 60px 0px;
  @media ${device.md} {
    padding: 104px 136px;
  }
`;

const Container = styled.div`
  @media ${device.md} {
    max-width: 1000px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
`;

const ArticlePage = ({
  pageContext,
  data: { prismicBlogArticle },
}: BlogPageProps) => {
  if (!prismicBlogArticle)
    return (
      <NotFoundPage
        pageContext={pageContext}
        navigate={(url) => {
          return (window.location = url);
        }}
      />
    );
  useIntl();
  const { language } = pageContext;
  const [cta, setCta] = useState<CTAProps | undefined>();
  const { data: article = null, ...articleInfo } = prismicBlogArticle;
  const [relatedArticles, setRelatedArticles] = useState([]);
  const readingTime = countWords(
    article.body.filter((val) => val.slice_type === 'paragraph')
  );
  const alternativeLanguages = {
    languages: prismicBlogArticle.alternate_languages,
    path: '/blog/',
  };

  useEffect(() => {
    if (!article) return;
    if (article && article.cta_headline) {
      setCta({
        cta_title: article.cta_headline,
        cta_description: article.cta_description,
        cta_button_label: article.cta_button_label,
        cta_button_action: article.cta_button_action,
      });
    }
    if (article.related_article_1.document !== null) {
      setRelatedArticles((prevState) => [
        ...prevState,
        article.related_article_1.document,
      ]);
    }
    if (article.related_article_2.document !== null) {
      setRelatedArticles((prevState) => [
        ...prevState,
        article.related_article_2.document,
      ]);
    }
    if (article.related_article_3.document !== null) {
      setRelatedArticles((prevState) => [
        ...prevState,
        article.related_article_3.document,
      ]);
    }
  }, []);

  return (
    <div>
      <Layout lang={language} alternativeLanguages={alternativeLanguages}>
        <SEO
          title={article?.seo_page_title || article?.hero_title || null}
          description={
            article?.seo_page_description || article?.excerpt || null
          }
          lang={language}
        />
        {article.cover_image && article.cover_image.url && (
          <Hero>
            <img
              src={article.cover_image.url}
              alt={article.cover_image.alt || article.hero_title || ''}
            />
          </Hero>
        )}
        <Container className="m-section__wrap">
          <Teaser>
            <div className="title">{article.hero_title}</div>

            <Author>
              {article.author.document && (
                <div className="author-pfp">
                  <AuthorPFP
                    src={article.author.document.data.profile_photo.url}
                    alt={article.author.document.data.profile_photo.alt}
                  />
                </div>
              )}
              <div className="author-details">
                {article.author.document && (
                  <div className="author-name">
                    {article.author.document.data.full_name}
                  </div>
                )}
                <div className="author-date">
                  {articleInfo.last_publication_date}
                </div>
                {readingTime && (
                  <>
                    <div className="author-reading_time">
                      &nbsp; | &nbsp;
                      {calcReadingTime(readingTime)} min read
                    </div>
                  </>
                )}
              </div>
            </Author>
          </Teaser>
          {article.body.map(({ slice_type, id, items }, index) => {
            if (slice_type === 'paragraph')
              return <Text key={`${id}_${index}`} id={id} items={items} />;
            if (slice_type === 'image')
              return <Image key={`${id}_${index}`} id={id} items={items} />;
            if (slice_type === 'quote')
              return <Quote key={`${id}_${index}`} id={id} items={items} />;
          })}
        </Container>
        {cta && <CTA {...cta} />}
        {relatedArticles.length > 0 && (
          <RelatedArticlesStyled>
            <RelatedArticles
              title={article.i18n_related_articles || null}
              articles={relatedArticles}
            />
          </RelatedArticlesStyled>
        )}
      </Layout>
    </div>
  );
};

export const query = graphql`
  fragment ArticlePartials on PrismicBlogArticle {
    uid
    last_publication_date(formatString: "DD.MM.YYYY")
    tags
    data {
      hero_title
      excerpt
      cover_image {
        alt
        url
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
      author {
        document {
          ... on PrismicBlogAuthor {
            id
            data {
              full_name
              profile_photo {
                alt
                url
              }
            }
          }
        }
        target
      }
      body {
        ... on PrismicBlogArticleDataBodyParagraph {
          slice_type
          items {
            title
            text {
              raw
              text
            }
          }
        }
      }
    }
  }
  query PrismicBlogArticle($slug: String, $prismicLang: String) {
    prismicBlogArticle(uid: { eq: $slug }, lang: { eq: $prismicLang }) {
      uid
      lang
      last_publication_date(formatString: "DD.MM.YYYY")
      alternate_languages {
        lang
        uid
      }
      data {
        cta_headline
        cta_description
        cta_button_label
        cta_button_action {
          isBroken
          target
          url
        }
        cover_image {
          alt
          url
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
        seo_page_title
        seo_page_description
        hero_title
        excerpt
        i18n_related_articles
        author {
          document {
            ... on PrismicBlogAuthor {
              id
              data {
                full_name
                profile_photo {
                  alt
                  url
                }
              }
            }
          }
        }
        body {
          ... on PrismicBlogArticleDataBodyImage {
            slice_type
            id
            items {
              image {
                url
                alt
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
          ... on PrismicBlogArticleDataBodyParagraph {
            slice_type
            id
            items {
              title
              text {
                html
                text
              }
            }
          }
          ... on PrismicBlogArticleDataBodyQuote {
            slice_type
            id
            items {
              quote_author
              quote_text {
                html
                text
              }
            }
          }
        }
        related_article_1 {
          document {
            ...ArticlePartials
          }
        }
        related_article_2 {
          document {
            ...ArticlePartials
          }
        }
        related_article_3 {
          document {
            ...ArticlePartials
          }
        }
      }
    }
  }
`;

/* eslint-disable-next-line */
// @ts-ignore eslint-disable-line
export default withPrismicPreview(ArticlePage, [
  {
    repositoryName: 'deeptrue-blog',
  },
]);

import React, { useEffect } from 'react';
import { Typography, Button } from '@deeptrue-frontend/ui';
import { useIntl } from 'gatsby-plugin-intl';
import Logo from '../components/Logo';

import Layout from '../components/layout';
import SEO from '../components/seo';

type NotFoundPageProps = {
  pageContext: {
    language: string;
  };
  navigate: Function;
};
const NotFoundPage = ({ pageContext, navigate }: NotFoundPageProps) => {
  const intl = useIntl();
  const isBrowser = typeof window !== 'undefined' && window;
  const { language } = pageContext;

  useEffect(() => {
    const timeout = setTimeout(() => navigate(`/${language || 'en'}/`), 10000);

    return () => clearTimeout(timeout);
  });

  return (
    isBrowser && (
      <Layout lang={language}>
        <SEO title="404: Not found" />
        <div className="no-match">
          <Logo className="no-match__logo" />
          <Typography variant={'h5'}>404</Typography>
          <Typography variant={'copy1'} mt={3}>
            {intl.formatMessage({
              id: 'notfound',
              defaultMessage: "Whoops, we can't find this page",
            })}
          </Typography>
          <Button
            onClick={() => {
              navigate(`/${language || 'en'}/`);
            }}
            mt="2rem"
          >
            {intl.formatMessage({
              id: 'notfound__cta',
              defaultMessage: 'GO TO HOMEPAGE',
            })}
          </Button>
        </div>
      </Layout>
    )
  );
};

export default NotFoundPage;

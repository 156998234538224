import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import React from 'react';
import Spacer from './Spacer';
import styled from 'styled-components';

type QuoteProps = {
  id: string | null;
  key?: string;
  items: {
    image: {
      alt: string | null;
      url: string;
      gatsbyImageData: any;
    };
  }[];
};

const ImageStyled = styled.img`
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: auto;
`;

const Quote = ({ id, items }: QuoteProps) => {
  return (
    <>
      {items && (
        <Spacer>
          {items.map(({ image: { alt, url, gatsbyImageData } }, index) => {
            if (gatsbyImageData) {
              const image = getImage(gatsbyImageData);
              return (
                <GatsbyImage
                  image={image}
                  alt={alt || ''}
                  key={`${id}_${index}`}
                />
              );
            }
            return <ImageStyled src={url} alt={alt} key={`${id}_${index}`} />;
          })}
        </Spacer>
      )}
    </>
  );
};

export default Quote;

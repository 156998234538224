export const countWords = (array: any) => {
  let counter = 0;
  array.forEach(({ items: data }) => {
    data.forEach(({ text: { text: val } }) => {
      counter += val.split(' ').filter(function (n) {
        return n != '';
      }).length;
    });
  });
  return counter;
};

export const calcReadingTime = (words: number, wordsPerMin = 200) => {
  return words ? Math.round(words / wordsPerMin) || null : null;
};

export const shortenExcerpt = (
  str: string,
  maxLen = 160,
  separator = ' ',
  ending = '...'
) => {
  if (str.length <= maxLen) return str;
  return (
    str.substr(0, str.lastIndexOf(separator, maxLen - ending.length)) + ending
  );
};

import { AuthorProps, CoverImageProps } from '../../utils/blog/types';
import { useIntl } from 'gatsby-plugin-intl';
import { Link } from 'gatsby';
import { calcReadingTime, shortenExcerpt } from '../../utils/blog';

import React from 'react';
import { device } from '../../utils/blog/breakpoints';
import styled from 'styled-components';

export type ArticleCardProps = {
  cover_image: CoverImageProps | null;
  tags?: string[] | null;
  hero_title: string | null;
  word_count?: number;
  excerpt: string | null;
  author: AuthorProps | null;
  link: string | null;
  last_published: Date | null;
};

const Card = styled(Link)`
  border: 1px solid #f1f2f3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;

  background: #ffffff;

  border: 1px solid #f1f2f3;
  box-sizing: border-box;
  box-shadow: 2px 2px 2px rgba(37, 56, 88, 0.05);
  border-radius: 8px;
  overflow: hidden;
`;

const Content = styled.div`
  padding: 33px 24px 40px;
  height: 100%;
  display: flex;
  flex-direction: column;

  .tags {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 14px;
    @media ${device.md} {
      font-size: 16px;
    }
    line-height: 150%;
  }
  .title {
    display: block;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 8px;
  }
  .excerpt {
    font-weight: 400;
    font-size: 15px;
    @media ${device.md} {
      font-size: 16px;
    }
    line-height: 24px;
    margin-bottom: auto;
  }
`;

const ImageStyled = styled.img`
  width: 100%;
  max-width: 100%;
  min-height: 224px;
  object-fit: cover;
`;

const Author = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  @media ${device.md} {
    margin-top: 44px;
  }

  .author-name {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  .author-date,
  .author-reading_time {
    display: inline-block;
    font-size: 14px;
    line-height: 20px;
  }
`;

export const AuthorPFP = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 100%;
  object-fit: cover;
  object-position: center;
  margin-right: 16px;
`;

const ArticleCard = (props: ArticleCardProps) => {
  const intl = useIntl();
  const readingTime = calcReadingTime(props.word_count);
  const author = props.author.document?.data || null;

  return (
    <Card to={props.link} hrefLang={intl.locale}>
      <ImageStyled src={props.cover_image.url} alt={props.cover_image.alt} />
      <Content>
        {props.tags && props.tags.length > 0 && (
          <div className="tags">
            {props.tags.map((tag, index) => (index ? ', ' : '') + tag)}
          </div>
        )}
        <div className="title">{props.hero_title}</div>
        <p className="excerpt">{shortenExcerpt(props.excerpt)}</p>
        <Author>
          {author && (
            <AuthorPFP
              src={author.profile_photo.url}
              alt={author.profile_photo.alt}
            />
          )}
          <div>
            {author && <div className="author-name">{author.full_name}</div>}
            <div className="d-flex">
              <div className="author-date">{props.last_published}</div>
              {readingTime && (
                <div className="author-reading_time">
                  &nbsp; | {readingTime} min read
                </div>
              )}
            </div>
          </div>
        </Author>
      </Content>
    </Card>
  );
};

export default ArticleCard;

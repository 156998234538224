import React from 'react';
import Spacer from './Spacer';
import styled from 'styled-components';

type QuoteProps = {
  id: string | null;
  key?: string;
  items: {
    quote_author: string | null;
    quote_text: { html: string | null } | null;
  }[];
};

const Author = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  color: #253858;
  margin-top: 8px;
  text-align: right;
  &:before {
    content: '— ';
  }
`;

const QuoteStyled = styled.div`
  font-style: italic;
  font-weight: 400;
  font-size: 24px;
  line-height: 133%;
  text-align: center;
  color: #253858;
  > *:before {
    content: '“';
  }
  > *:after {
    content: '”';
  }
`;

const Quote = ({ id, items }: QuoteProps) => {
  return (
    <>
      {items && (
        <Spacer>
          {items.map(({ quote_author, quote_text }, index) => {
            return (
              <div key={`${id}_${index}`}>
                <QuoteStyled
                  dangerouslySetInnerHTML={{ __html: quote_text.html }}
                />
                <Author>{quote_author}</Author>
              </div>
            );
          })}
        </Spacer>
      )}
    </>
  );
};

export default Quote;

import React, { useState } from 'react';

import { device } from '../../utils/blog/breakpoints';
import styled from 'styled-components';

export type CTAProps = {
  cta_title: string | null;
  cta_description: string | null;
  cta_button_label: string | null;
  cta_email_field_placeholder?: string | null;
  cta_button_action?: any | null;
  form_id?: string;
};

const Wrapper = styled.section`
  background-color: #001f33;
  padding: 60px 20px 50px;
  @media ${device.md} {
    padding: 84px 200px 76px;
  }
  display: flex;
  flex-direction: column;
  color: white;

  .title,
  .description {
    font-style: normal;
    text-align: center;
  }
  .title {
    font-size: 36px;
    line-height: 133%;
    @media ${device.md} {
      font-size: 48px;
      line-height: 64px;
    }
    font-weight: 500;
  }
  .description {
    font-size: 20px;
    line-height: 133%;
    @media ${device.md} {
      font-size: 24px;
      line-height: 32px;
    }
    margin-top: 10px;
    font-weight: 400;
  }
  form {
    margin-top: 20px;
    @media ${device.md} {
      margin-top: 40px;
    }
  }
`;

const Button = styled.a`
  background: #1f5dea;
  border-radius: 28px;
  padding: 19px 33px;
  max-width: max-content;
  color: white;
  margin: 0 auto;
  margin-top: 40px;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  cursor: pointer;
`;

const Input = styled.input`
  background: #ffffff;
  border: 1px solid #a4aecf;
  box-sizing: border-box;
  box-shadow: 0px 12px 20px rgba(55, 111, 237, 0.08);
  padding: 14px 12px;
  min-width: 320px;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  text-align: center;
  > div {
    max-width: 368px;
  }
  ${Input} {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 6px;
  }
  ${Button} {
    margin-top: 16px;
  }
  @media ${device.md} {
    flex-direction: row;
    text-align: left;
    align-items: start;
    justify-content: center;
    ${Input} {
      margin-right: 16px;
      margin-bottom: 6px;
    }
    ${Button} {
      margin: 0;
    }
  }
`;

const CTA = (props: CTAProps) => {
  const [email, setEmail] = useState<string>('');
  const [feedback, setFeedback] = useState<string | null>();

  const handleSubmission = async () => {
    const hutk = window?.__hsUserToken || null;
    const body = {
      fields: [
        {
          objectTypeId: '0-1',
          name: 'email',
          value: email,
        },
      ],
      context: {
        hutk,
        pageUri: window.location.href,
        pageName: document.title,
      },
    };
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    };
    const response = await fetch(
      `https://api.hsforms.com/submissions/v3/integration/submit/6092764/${props.form_id}`,
      requestOptions
    );
    if (response.status >= 200 && response.status <= 299) {
      const jsonResponse = await response.json();
      if (jsonResponse.inlineMessage) setFeedback(jsonResponse.inlineMessage);
      setEmail('');
    } else {
      const error = JSON.parse(await response.text());
      if (error.errors) {
        setFeedback(error.errors[0].message);
      }
    }
  };

  return (
    <Wrapper>
      <div className="title">{props.cta_title}</div>
      {props.cta_description && (
        <div className="description">{props.cta_description}</div>
      )}
      {props.cta_button_action?.url && props.cta_button_label && (
        <Button
          target={props.cta_button_action.target || '_self'}
          href={props.cta_button_action.url}
        >
          {props.cta_button_label}
        </Button>
      )}
      {props.cta_email_field_placeholder && (
        <Form>
          <div>
            <Input
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              placeholder={props.cta_email_field_placeholder}
            />
            {feedback && <div>{feedback}</div>}
          </div>
          <Button onClick={handleSubmission}>{props.cta_button_label}</Button>
        </Form>
      )}
    </Wrapper>
  );
};

export default CTA;

import React from 'react';
import { device } from '../../../utils/blog/breakpoints';
import styled from 'styled-components';

const SpacerStyled = styled.div<{ size: string }>`
  margin-bottom: ${(props) => (props.size === 'md' ? '52px' : '42px')};
  @media ${device.md} {
    margin-bottom: ${(props) => (props.size === 'md' ? '64px' : '32px')};
  }
`;

const Spacer = ({ size = 'md', children }) => {
  return <SpacerStyled size={size}>{children}</SpacerStyled>;
};

export default Spacer;

import React from 'react';
import Spacer from './Spacer';
import styled from 'styled-components';

type TextProps = {
  id: string | null;
  key?: string;
  items: {
    title: string | null;
    text: {
      html: string | null;
    } | null;
  }[];
};

const Title = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #253858;
  margin-bottom: 8px;
`;

const Paragraph = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #253858;

  ul {
    margin-bottom: 16px;
  }

  li {
    list-style: inside;
  }
`;

const Text = ({ id, items }: TextProps) => {
  return (
    <>
      {items && (
        <Spacer>
          {items.map(({ title, text }, index) => {
            return (
              <div key={`${id}_${index}`}>
                <Title>{title}</Title>
                <Paragraph dangerouslySetInnerHTML={{ __html: text.html }} />
              </div>
            );
          })}
        </Spacer>
      )}
    </>
  );
};

export default Text;
